import React from "react"
import RootElement from "./src/components/root-element"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
